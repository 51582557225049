import React from "react"
import { Link, graphql } from "gatsby"

import Img from "gatsby-image"

import BlockContent from "../components/block-content"
import Layout from "../components/layout"
import SEO from "../components/seo"

import indexPageStyles from "../styles/index.module.scss"

export const query = graphql`
  query IndexPageQuery {
    about: sanityPage(_id: { eq: "61380659-0ba5-45a6-b3c4-568078c2e2a2" }) {
      title
      _rawContent
      image {
        asset {
          fluid(maxWidth: 700, maxHeight: 300) {
            ...GatsbySanityImageFluid
          }
        }
      }
    }
  }
`

const IndexPage = ({ data }) => {
  const about = data && data.about
  return (
    <Layout>
      <SEO
        title="Home Page"
        bodyClass="home"
        description="Welcome to Crazy Critters Experience - educating and entertaining people of all ages about reptiles and other exotic animals"
      />
      <h1>Welcome To Crazy Critters Experience</h1>
      <section className={indexPageStyles.aboutContainer}>
        <h2>{about.title}</h2>
        {/* <img
          className={indexPageStyles}
          src={imageUrlFor(buildImageObj(about.image))
            .width(315)
            .height(300)
            .maxWidth(315)
            .maxHeight(300)
            .fit("crop")
            .crop("focalpoint")
            .url()}
          //Add proper alt tags to sanity studio
          alt="about page"
        /> */}

        <Img
          fluid={about.image.asset.fluid}
          className={indexPageStyles.imageWrapper}
        />

        {//Figure out how to add images to blockcontent
        about._rawContent && <BlockContent blocks={about._rawContent} />}
        <Link to="/">Read more about us ...</Link>
      </section>
    </Layout>
  )
}

export default IndexPage
